	import { CookieBytes } from "./libs/cookieManager";
import { ApiCalls } from "./libs/api_calls";

const cookieManager = new CookieBytes();

var api;

const commonHeaders = {
	"Content-Type": "application/json"
};

function loadLogo() {
	const gtlogo = document.getElementById('mainLogo');
	gtlogo.innerHTML = "SAHAYAK DASHBOARD";
	gtlogo.onclick = function() {
		window.location.href = "/";
	}
}

function getApiObject(url) {
	return new ApiCalls(url);
}

function getBackendUrl() {
	let backend_url;	
	if ( window.origin.indexOf('http://localhost:5058') > -1 ) {
		backend_url = "http://localhost:5500/";
	} else if ( window.origin.indexOf('https://dev.gtrippr.com') > -1 ){
		backend_url = "https://dev-backend.gtrippr.com/";
	} else if ( window.origin.indexOf('http://localhost:5080') > -1 ) {
		backend_url = "http://localhost:5500/";
		// backend_url = "https://dev-backend.gtrippr.com/";
	} else if ( window.origin.indexOf('http://192.168.1.8:5080') > -1 ) {
		backend_url = "http://192.168.1.8:5500/";
		// backend_url = "https://dev-backend.gtrippr.com/";
	}
	return backend_url;
}

function getAuthHeaders() {
	let authHeaders = {
		"GSH-USER-ID" : cookieManager.get("username"),
		"GSH-USER-API-KEY" : cookieManager.get("token"),
		"GSH-SESSION-TYPE" : cookieManager.get("session_type")
	}
	return Object.assign({}, commonHeaders, authHeaders);
}

function startNewSession(response) {
	if (response.status) {
		cookieManager.set("username", response.data.uid, 1);
		cookieManager.set("token", response.data.apikey, 1);
		cookieManager.set("session_type", response.data.owner, 1);
		console.log("Shopping session started.");
	} else {
		console.error("Unable to start a session.");
	}
}


function createNewSession() {
	api.postData("session/temp", getAuthHeaders(), [], {
		"owner": "anonymous"
	}, startNewSession);
}

function verifySession(response) {
	if (response.status) {
		console.log("Valid Session, continuing shopping.");
		return;
	} else {
		createNewSession();
		return;
	}
}

function initSession() {
	console.log("starting session..")
	api = getApiObject(getBackendUrl())
	let username = cookieManager.get("username");
	let token = cookieManager.get("token");
	let session_type = cookieManager.get("session_type");
	
	if (!username || !token || !session_type) {
		createNewSession();
		return;
	}
	api.postData("session/temp/verify-token", commonHeaders, [], {
		"username": username,
		"token": token
	}, verifySession);
	console.log("Done.")
	
}

function getQueryParams(query) {
	var vars = query.split("&");
	var query_string = {};
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		var key = decodeURIComponent(pair.shift());
		var value = decodeURIComponent(pair.join("="));
		// If first entry with this name
		if (typeof query_string[key] === "undefined") {
			query_string[key] = value;
			// If second entry with this name
		} else if (typeof query_string[key] === "string") {
			var arr = [query_string[key], value];
			query_string[key] = arr;
			// If third or later entry with this name
		} else {
			query_string[key].push(value);
		}
	}
	return query_string;
}


function getSubCategories(callback) {
	api.getData("object/category", getAuthHeaders(), ['filters={"parent":"null"}'], callback);
}

var chosenCategory = {

	getChosenCategory : () => {
		return cookieManager.get("chosenCategory")
	},

	setChosenCategory : (val) => {
		if (!val) {
			console.debug("categroy value not given or invalid.");
			return false;
		}
		cookieManager.set("chosenCategory", val, 7);
		return true
	}
	
}

function generateStars(n) {
	let star = "";
	for (let i = 1; i <= n; i++) {
		star = star+"&#9733;";
	}
	return star;
}

function getStarElements(n) {
	let stars = document.createElement("div");
	stars.className = "star-rating";
	stars.innerHTML = generateStars(n);
	return stars;
}

function getAddToCartButton() {
	// <button class=" add-to-cart-button">Add to Cart</button>
	let atcbutton = document.createElement("button");
	atcbutton.className = "add-to-cart-button";
	atcbutton.innerHTML = "Add To Cart";
	return atcbutton;
}


export {
	cookieManager, 
	getAuthHeaders, 
	getBackendUrl, 
	getApiObject, 
	commonHeaders, 
	initSession, 
	loadLogo, 
	getQueryParams, 
	getSubCategories, 
	chosenCategory, 
	getStarElements,
	getAddToCartButton
}